import logo from './images/logo_512.png';

function App() {
  return (
    <div
      className="col"
      style={{
        minHeight: "100vh",
        width: "100vw"
      }}>
      <div className="col">
        <img
          src={logo}
          alt="mononymous logo"
          style={{
            width: "512px",
            maxWidth: "100vw",
            height: "auto",
          }}
        />
        <h1
          style={{
            fontSize: "60px",
          }}
        >
          mononymous
        </h1>
      </div>
    </div>
  );
}

export default App;
